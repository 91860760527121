export const AI_ALERT_MAPPING = {
  AUTOALERTVSF: 'VideoStartFailures',
  AUTOALERTVST: 'VideoStartTime',
  AUTOALERTBUFFERINGRATIO: 'RebufferingRatio',
  AUTOALERTEBVS: 'ExitBeforeVideoStarts',
  AUTOALERTCIRR: 'ConnectionInducedRebufferingRatio',
  AUTOALERTVPF: 'VideoPlaybackFailures',
  AUTOALERTPCP: 'ConcurrentPlays',
  AUTOALERTBITRATE: 'Bitrate',
  AUTOALERTPLAYS: 'Plays',
  AUTOALERTATTEMPTS: 'Attempts',
  AUTOALERTENDEDPLAYS: 'EndedPlays',
  AD_AUTOALERTVSF: 'AdVideoStartFailures',
  AD_AUTOALERTBUFFERINGRATIO: 'AdRebufferingRatio',
  AD_AUTOALERTBITRATE: 'AdBitrate',
  AD_AUTOALERTSLATEDURATIONRATIO: 'AdSlateDurationPct',
  // APP mapping
  // we change the API for bell, so add the APP_ prefix back
  APP_SCREEN_LOAD_TIME: 'AppAvgScreenLoadTime',
  APP_NETWORK_REQUEST_TIME: 'AverageRequestDuration',
  APP_NETWORK_REQUEST_FAILURE: 'FailedNetworkRequest',
  APP_PERCEIVED_LOAD_TIME: 'AvgPerceivedLoadingTime',
  APP_ACTIVE_DEVICES: 'AppUniqueDevices',
};

export const MULTILPIER = {
  AUTOALERTVSF: 100,
  AUTOALERTBUFFERINGRATIO: 100,
  AUTOALERTCIRR: 100,
  AUTOALERTEBVS: 100,
  AUTOALERTVPF: 100,
  AD_AUTOALERTVSF: 100,
  AD_AUTOALERTBUFFERINGRATIO: 100,
  AD_AUTOALERTEBVS: 100,
  AD_AUTOALERTSLATEDURATIONRATIO: 100,
};
